<template>
  <div>
    <UIContract
      v-for="(contract, index) in data?.contracten"
      :key="`${index}-${contract.id}-${contract.rit_id}`"
      force-number
      :signature="contract?.imageSrc"
      :contract="contract"
      :wagens="data?.wagens"
      print
      class="page-break"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import UIContract from '@/components/UI/Contract.vue'
import useGetApi from '@/hooks/useGetApi'

const route = useRoute()
const apiData = computed(() => ({ date: route.query.date || 0 }))

const { data } = useGetApi('CONTRACTEN_PRINT', apiData, { watch: false })
</script>
